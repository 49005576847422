<template lang="pug">
    section.content
        div.container-fluid
            div.row
                div.col-12
                    div.card
                        div.card-header
                            h3.card-title Cursos
                            div.card-tools
                                div.input-group.input-group-sm(style="width: 150px;")
                                    div.input-group-append
                                        router-link.btn.btn-default(to="/content/CreateExam") Crear Examen

                                            
                        div.card-body.table-responsive.p-0(style="height: 500px;")
                            table.table.table-head-fixed.text-nowrap
                                thead
                                    tr
                                        th Nombre del Examen
                                        th Id Pool
                                        th No de preguntas
                                        th Tiempo para Responder
                                        th Procentaje de Aprobación
                                        th Acciones
                                tbody
                                    tr(v-for="exam in exams")
                                        td {{ exam.sExamName }}
                                        td {{ exam.idPool }}
                                        td {{ exam.iNoQuestions }}
                                        td {{ exam.iTimeToAnswer }}
                                        td {{ exam.iAprovePercent }}
                                        td 
                                            button.btn-sm.btn-primary.btn-block(type="button" @click="goExamEdit(exam.idPool)") Editar Examen
                                            button.btn-sm.btn-primary.btn-block(type="button" @click="goQuestions(exam.idPool)") Preguntas
                        div.card-footer
                            router-link.btn.btn-primary(to="/content/contenthome" ) Regresar
</template>
<script>
/* eslint-disable */
export default {
    name: 'CreateCourse',
    components: {
    },    
    data() {
        return {
            exams: []
        }
    },
    methods: {
        async getCourses() {
            await axios.get(apiURL + 'Exam/GetExamsList', { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.exams = response.data.examsList
            } )
            .catch( (error)  => {
                console.log("error")
            } )            
        },
        goExamEdit(idPool) {
            router.push('/content/ExamEdith/' + idPool)
        },

        goQuestions(idPool) {
            router.push('/content/AddQuestion/' + idPool)
        }
    },
    mounted() {
        this.getCourses()
    }    
        
}
</script>